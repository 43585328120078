<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <a-alert message="暂时仅支持查询前一日数据" type="warning" show-icon style="margin-bottom: 30px"/>
                <div class="head-box">
                    <div class="table-operator">
                        <span class="item">
                            <a-input v-model="form.phone" placeholder="用户手机号" style="width: 210px;"/>
                        </span>
                        <span class="item">
                            <treeselect @treeDataChange="treeDataChange" :isDateShow="isDateShow"></treeselect>
                        </span>
                        <span class="item">
                            <a-select ref="select" v-model="form.registerSource" placeholder="注册方式" style="width: 210px;">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">APP_Android</a-select-option>
                                <a-select-option :value="2">APP_iOS</a-select-option>
                                <a-select-option :value="3">直播_视频号</a-select-option>
                                <a-select-option :value="4">直播_抖音</a-select-option>
                                <a-select-option :value="5">直播预约小程序</a-select-option>
                                <a-select-option :value="6">H5阅读大使推广二维码</a-select-option>
                                <a-select-option :value="7">H5推广大使二维码</a-select-option>
                                <a-select-option :value="8">人工操作</a-select-option>
                                <a-select-option :value="9">历史注册</a-select-option>
                                <a-select-option :value="10">H5官方网站</a-select-option>
                                <a-select-option :value="99">其他</a-select-option>
                            </a-select>
                        </span>
                        <span class="item">
                            <a-select allowClear v-model="form.courseName" show-search placeholder="课程/商品关键字" style="width: 210px" :default-active-first-option="false" :show-arrow="false" :filter-option="false" :not-found-content="null" :options="options" @search="handleSearch"></a-select>
                        </span>
                        <span class="item">
                            <a-select ref="vipStatus" v-model="form.vipStatus" placeholder="会员状态" style="width: 210px;">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">仅注册</a-select-option>
                                <a-select-option :value="2">会期内</a-select-option>
                                <a-select-option :value="3">会期过期</a-select-option>
                            </a-select>
                        </span>
                        <span class="item">
                            <!--<a-date-picker @change="registerChange" v-model="form.datePicker" placeholder="注册日期" style="width: 210px;"/>-->
                            <a-range-picker @change="registerChange" v-model="form.datePicker" :placeholder="['注册开始日期', '注册结束日期']" style="width: 210px;"/>
                        </span>
                        <span class="item">
                            <a-range-picker @change="onChangeDate" v-model="form.datePickerArr" :placeholder="['最近付费开始日期', '最近付费结束日期']" style="width: 210px;"/>
                        </span>
                        <span class="item">
                            <a-select ref="select" v-model="form.vipEnd" placeholder="会期到期时间范围" style="width: 210px;">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">一天内到期</a-select-option>
                                <a-select-option :value="3">三天内到期</a-select-option>
                                <a-select-option :value="7">一周内到期</a-select-option>
                                <a-select-option :value="30">一月内到期</a-select-option>
                                <a-select-option :value="90">三月内到期</a-select-option>
                            </a-select>
                        </span>
                        <span class="item">
                            <a-select ref="select" v-model="form.gmv" placeholder="消费GMV范围" @change="gmvChange" style="width: 210px;">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">0-500</a-select-option>
                                <a-select-option :value="2">500-1000</a-select-option>
                                <a-select-option :value="3">1000-3000</a-select-option>
                                <a-select-option :value="4">3000-5000</a-select-option>
                                <a-select-option :value="5">5000-7000</a-select-option>
                                <a-select-option :value="6">7000-9000</a-select-option>
                                <a-select-option :value="7">9000-11000</a-select-option>
                                <a-select-option :value="8">11000-13000</a-select-option>
                                <a-select-option :value="9">13000-15000</a-select-option>
                                <a-select-option :value="10">15000-20000</a-select-option>
                                <a-select-option :value="11">20000+</a-select-option>
                            </a-select>
                        </span>
                        <span class="item">
                            <a-button class="margin_right10" type="primary" @click="searchClick">搜索</a-button>
                            <a-button class="margin_right10" type="primary" @click="resetClick">重置</a-button>
                            <a-button type="primary" @click="exportClick" v-if="isExport">导出</a-button>
                        </span>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" @expand="expandClick" :row-key="record => record.userNo" :expandedRowKeys="expandedRowKeys" :loading="tableLoading" :pagination="false" @change="sorterFun">
                        <span slot="vipStatus" slot-scope="text, record">
                            <span>{{ record.vipStatus == 1 ? '仅注册' : record.vipStatus == 2 ? '会期内' : record.vipStatus == 3 ? '会期过期' : '--' }}</span>
                        </span>
                        <span slot="nickName" slot-scope="text, record">
                            <img v-viewer class="avatar" style="border-radius: 50%" :src="record.imageUrl" alt="头像"/>
                            <span>{{ record.nickName }}</span>
                        </span>
                        <span slot="source" slot-scope="text, record">
                            <span v-if="record.source == 1">APP_Android</span>
                            <span v-else-if="record.source == 2">APP_iOS</span>
                            <span v-else-if="record.source == 3">直播_视频号</span>
                            <span v-else-if="record.source == 4">直播_抖音</span>
                            <span v-else-if="record.source == 5">直播预约小程序</span>
                            <span v-else-if="record.source == 6">H5阅读大使推广二维码</span>
                            <span v-else-if="record.source == 7">H5大使推广二维码</span>
                            <span v-else-if="record.source == 8">人工操作</span>
                            <span v-else-if="record.source == 9">历史注册</span>
                            <span v-else-if="record.source == 10">H5官方网站</span>
                            <span v-else-if="record.source == 99">其他</span>
                            <span v-else>--</span>
                        </span>
                        <span slot="lastPay" slot-scope="text, record">
                            <span>{{ record.lastPay == '0001-01-01 00:00:00' ? '--' : record.lastPay }}</span>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <span class="margin_right10">
                                <a-dropdown>
                                    <a class="ant-dropdown-link" @click.prevent>更多操作</a>
                                    <template #overlay>
                                        <a-menu>
                                            <a-menu-item v-if="isUserDetail">
                                                <a style="color: #1890ff" @click="userDetails(record)">查看用户详情</a>
                                            </a-menu-item>
<!--                                            <a-menu-item>-->
<!--                                                <a @click="onAdd(record)">查看近期学习使用行为</a>-->
<!--                                            </a-menu-item>-->
<!--                                            <a-menu-item>-->
<!--                                                <a @click="onAdd(record)">查看用户权益变更</a>-->
<!--                                            </a-menu-item>-->
<!--                                            <a-menu-item>-->
<!--                                                <a style="color: #1890ff" @click="userAttributionClick(record)">查看用户归属变更</a>-->
<!--                                            </a-menu-item>-->
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </span>
                            <a v-if="record.enableFlag == 2 && isLockUser" @click="isLockingClick(record)">正常</a>
                            <a v-else-if="record.enableFlag == 1 && isLockUser" @click="isLockingClick(record)">锁定</a>
                        </span>
                        <div slot="expandedRowRender" slot-scope="record">
                            <ul class="contentRender">
                                <li class="tables">
                                    <div class="items">
                                        <p class="title">注册归属：</p>
                                        <p class="substance">{{ record.registerAgentName }}</p>
                                    </div>
                                    <div class="items">
                                        <p class="title">购买商品/课程：</p>
                                        <p class="substance">{{ !record.buyCourse ? '--' : record.buyCourse }}</p>
                                    </div>
                                    <div class="items">
                                        <p class="title">会员VIP日期：</p>
                                        <p class="substance">{{ record.vipStart == '0001-01-01 00:00:00' ? '' : record.vipStart }} -- {{ record.vipEnd == '0001-01-01 00:00:00' ? '' : record.vipEnd }}</p>
                                    </div>
                                    <div class="items">
                                        <p class="title">学习阶段：</p>
                                        <p class="substance">{{ record.grade == 0 ? '学前' : record.grade == 1 ? '一年级' : record.grade == 2 ? '二年级' : record.grade == 3 ? '三年级' : record.grade == 4 ? '四年级' : record.grade == 5 ? '五年级' : record.grade == 6 ? '六年级' : record.grade == 7 ? '初一' : record.grade == 8 ? '初二' : record.grade == 9 ? '初三' : record.grade == 10 ? '高一' : record.grade == 11 ? '高二' : record.grade == 12 ? '高三' : record.grade == 13 ? '自由学习者' : '--'}}</p>
                                    </div>
                                    <div class="items">
                                        <p class="title">生日：</p>
                                        <p class="substance">{{ record.birthday }}</p>
                                    </div>
                                    <div class="items">
                                        <p class="title">性别：</p>
                                        <p class="substance">{{ record.sex == 1 ? '男' : record.sex == 2 ? '女' : record.sex == 3 || record.sex == 0 ? '保密' : '--' }}</p>
                                    </div>
                                    <div class="items">
                                        <p class="title">账号状态：</p>
                                        <p class="substance">{{ record.enableFlag == 1 ? '正常' : '锁定' }}</p>
                                    </div>
                                </li>
                                <li class="chart">
                                    <div class="chartTitle">最近一周打开APP的次数：{{varsum}}</div>
                                    <div ref="echartPicRef" class="chartCom"></div>
                                </li>
                            </ul>
                        </div>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            </div>
        </a-spin>

        <!--    点击锁定按钮弹出框    -->
        <a-modal v-model="visible" title="账号锁定" @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :model="locking" :rules="rules" >
                <a-form-model-item ref="name" label="用户名称" prop="name">
                    <a-input v-model="locking.name" :disabled="true" placeholder="请输入用户名称" />
                </a-form-model-item>
                <a-form-model-item ref="phone" label="用户手机号" prop="phone">
                    <a-input v-model="locking.phone" :disabled="true" placeholder="请输入用户手机号" />
                </a-form-model-item>
                <a-form-model-item ref="isLocking" label="账号状态" prop="isLocking">
                    <a-radio-group v-model="locking.isLocking" name="radioGroup">
                        <a-radio :value="1">正常</a-radio>
                        <a-radio :value="2">锁定</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="remarks" label="状态备注" prop="remarks">
                    <a-textarea :rows="4" v-model="locking.remarks" placeholder="请输入状态备注" :max-length="6" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <!--    导出弹框    -->
        <a-modal v-model="visibleShow" title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button @click="visibleShow = false" class="margin_right20">取消</a-button>
                    <download-excel
                        :data="exportList"
                        :fields="tableDataName"
                        name="用户数据.xls">
                        <a-button type="primary" @click="visibleShow = false">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p>
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 10px">确定要导出此数据吗？</span>
            </p>
        </a-modal>

        <router-view/>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import Treeselect from "@/components/channel/treeselect";
import MyPagination from "@/components/pagination/MyPagination";
import {LockUser, UserList, OpenCount, SearchCourse} from "@/request/api/userManage";
import {codeFn} from "@/utils/tools";
export default {
    components: {MyPagination,Treeselect},
    created() {
        if (codeFn("/adminv2/usercenter/lockUser")) this.isLockUser = true;
        if (codeFn("/adminv2/usercenter/userDetail")) this.isUserDetail = true;
        if (codeFn("/adminv2/usercenter/userList/export")) this.isExport = true;
        this.getTagList();
    },

    data() {
        return {
            isLockUser: false,
            isUserDetail: false,
            isExport: false,
            form:{
                phone: '',
                channel:[],
                registerSource:undefined,
                courseName:undefined,
                vipStatus:undefined,
                registerDate:'',
                registerEndDate:'',
                vipEnd:undefined,
                gmv:undefined,
                gmval:'',
                lastPayStart:'',
                lastPayEnd:'',
                datePicker:[],
                datePickerArr:[],
                sortType:'',
                sortField:'',
            },
            visible:false,
            visibleShow:false,
            spinning: false, // 全局loading
            tableLoading: false,
            expandedRowKeys:[],
            columns: [
                {
                    title: "用户手机",
                    dataIndex: "userName",
                    key: "userName",
                },
                {
                    title: "用户姓名",
                    dataIndex: "nickName",
                    key: "nickName",
                    scopedSlots: {customRender: "nickName"},
                },
                {
                    title: "会期状态",
                    dataIndex: "vipStatus",
                    key: "vipStatus",
                    scopedSlots: {customRender: "vipStatus"},
                },
                {
                    title: "所属渠道",
                    dataIndex: "newestAgentName",
                    key: "newestAgentName",
                },
                {
                    title: "所在地",
                    dataIndex: "phoneBelong",
                    key: "phoneBelong",
                },
                {
                    title: "注册方式",
                    dataIndex: "source",
                    key: "source",
                    scopedSlots: {customRender: "source"},
                },
                {
                    title: "注册时间",
                    dataIndex: "registerTime",
                    key: "registerTime",
                },
                {
                    title: "最近付费时间",
                    dataIndex: "lastPay",
                    key: "lastPay",
                    scopedSlots: {customRender: "lastPay"},
                },
                {
                    title: "总GMV",
                    dataIndex: "totalGmv",
                    key: "totalGmv",
                    sorter: true,
                },
                {
                    title: "可用积分",
                    dataIndex: "totalCredit",
                    key: "totalCredit",
                    sorter: true,
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "130px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            //锁定表单
            locking:{
                userNo:'',
                name:'',
                phone:'',
                isLocking:1,
                remarks:'',
            },
            rules: {
                isLocking: [{ required: true, message: "请选择账号状态", trigger: "change" }],
            },
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0,// 列表的总条数

            isDateShow:true,

            exportList:[],
            tableDataName: {
                "用户手机号": "userName",
                "用户姓名": "nickName",
                "会期状态": {
                    field: "vipStatus",
                    callback: value => {
                        return value == 1 ? '仅注册' : value == 2 ? '会期内' : value == 3 ? '会期过期' : '--'
                    }
                },
                "所属渠道": "newestAgentName",
                "所在地": "phoneBelong",
                "注册方式": {
                    field: "source",
                    callback: value => {
                        return value == 1 ? 'APP_Android' : value == 2 ? 'APP_iOS' : value == 3 ? '直播_视频号' : value == 4 ? '直播_抖音' : value == 5 ? '直播预约小程序' : value == 6 ? 'H5阅读大使推广二维码' : value == 6 ? 'H5阅读大使推广二维码' : value == 7 ? 'H5大使推广二维码' : value == 8 ? '人工操作' : value == 9 ? '历史注册' : value == 10 ? 'H5官方网站' : value == 99 ? '其他' : '--'
                    }
                },
                "注册时间": "registerTime",
                "最近付费时间": {
                    field: "lastPay",
                    callback: value => {
                        return value == '0001-01-01 00:00:00' ? '--' : value
                    }
                },
                "总GMV": "totalGmv",
                "可用积分": "totalCredit",
                "购买商品/课程": {
                    field: "buyCourse",
                    callback: value => {
                        return value ? value : '--'
                    }
                },
                "会员VIP日期开始时间": {
                    field: "vipStart",
                    callback: value => {
                        return value == '0001-01-01 00:00:00' ? '--' : value
                    }
                },
                "会员VIP日期结束时间": {
                    field: "vipEnd",
                    callback: value => {
                        return value == '0001-01-01 00:00:00' ? '--' : value
                    }
                },
            },

            varsum:0,
            options:[],
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/userManage/userList") {
                this.getTagList();
                let userNo = this.expandedRowKeys.toString()
                if(userNo) this.init(userNo)
            }
        },
    },

    methods: {
        sorterFun(pagination, filters, sorter){
            this.pageNo = 1
            this.pageSize = this.pageSize
            this.form.sortType = sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : ''
            this.form.sortField = sorter.columnKey == "totalGmv" ? 'total_gmv' : 'total_credit'
            this.getTagList()
        },

        // 搜索
        searchClick() {
            this.pageNo = 1
            this.pageSize = this.pageSize
            this.options = []
            this.getTagList()
            let str = this.expandedRowKeys.toString()
            if(str) this.init(str)
        },

        // 点击重置
        resetClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.form.phone = ''
            this.form.channel = []
            this.form.registerSource = undefined
            this.form.courseName = undefined
            this.form.vipStatus = undefined
            this.form.registerDate = ''
            this.form.registerEndDate = ''
            this.form.vipEnd = undefined
            this.form.gmv = undefined
            this.form.gmval = ''
            this.form.lastPayStart = ''
            this.form.lastPayEnd = ''
            this.form.datePicker = []
            this.form.datePickerArr = []
            this.isDateShow = false
            this.getTagList()
        },

        // 折线图
        init(data) {
            this.$nextTick(() => {
                this.appNum(data)
            });
        },

        // 点击展开图标
        expandClick(expanded,record){
            if(expanded){
                if (this.expandedRowKeys.length > 0) { //进这个判断说明当前已经有展开的了
                    //返回某个指定的字符串值在字符串中首次出现的位置，下标为0
                    let index = this.expandedRowKeys.indexOf(record.userNo);
                    if (index > -1) { //如果出现则截取这个userNo,1d到1相当于0，针对重复点击一个
                        this.expandedRowKeys.splice(index, 1);
                        this.init(record.userNo)
                    } else {
                        //如果没出现则截取所有userNo,添加点击userNo，0到1，针对已经有一个展开，点另一个会进入判断
                        this.expandedRowKeys.splice(0, this.expandedRowKeys.length);
                        this.expandedRowKeys.push(record.userNo);
                        this.init(record.userNo)
                    }
                } else {
                    //数组长度小于0，说明都没展开，第一次点击，userNo添加到数组，数组有谁的userNo谁就展开
                    this.expandedRowKeys.push(record.userNo);
                    this.init(record.userNo)
                }
            } else {
                let index = this.expandedRowKeys.indexOf(record.id);
                this.expandedRowKeys.splice(index, 1);
            }
        },

        // 搜索·会期到期范围
        gmvChange(value){
            if(value == 0){
                this.form.gmval = ''
            }else if(value == 1){
                this.form.gmval = '0,500'
            }else if(value == 2){
                this.form.gmval = '500,1000'
            }else if(value == 3){
                this.form.gmval = '1000,3000'
            }else if(value == 4){
                this.form.gmval = '3000,5000'
            }else if(value == 5){
                this.form.gmval = '5000,7000'
            }else if(value == 6){
                this.form.gmval = '7000,9000'
            }else if(value == 7){
                this.form.gmval = '9000,11000'
            }else if(value == 8){
                this.form.gmval = '11000,13000'
            }else if(value == 9){
                this.form.gmval = '13000,15000'
            }else if(value == 10){
                this.form.gmval = '15000,20000'
            }else if(value == 11){
                this.form.gmval = '20000'
            }
        },

        // 搜索·用户归属
        treeDataChange(data){
            this.form.channel = data
            this.isDateShow = true
        },

        // 搜索 - 注册时间
        registerChange(date, dateString){
            if (dateString[0] != "") {
                this.form.registerDate = dateString[0];
                this.form.registerEndDate = dateString[1];
            } else {
                this.form.registerDate = "";
                this.form.registerEndDate = "";
            }
        },

        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.lastPayStart = dateString[0];
                this.form.lastPayEnd = dateString[1];
            } else {
                this.form.lastPayStart = "";
                this.form.lastPayEnd = "";
            }
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true
            UserList({
                phone:this.form.phone,
                channel:this.form.channel == undefined ? '' : this.form.channel.toString(),
                registerSource:this.form.registerSource == undefined ? 0 : this.form.registerSource,
                courseName:this.form.courseName == undefined ? '' : this.form.courseName,
                vipStatus:this.form.vipStatus == undefined ? 0 : this.form.vipStatus,
                registerDate:this.form.registerDate,
                registerEndDate:this.form.registerEndDate,
                lastPayStart:this.form.lastPayStart,
                lastPayEnd:this.form.lastPayEnd,
                vipEnd:this.form.vipEnd == undefined ? 0 : this.form.vipEnd,
                gmv:this.form.gmval,
                sortField:!this.form.sortType ? '' : this.form.sortField,
                sortType:this.form.sortType,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
  
      // 点击导出按钮
      exportClick(){
        UserList({
          phone:this.form.phone,
          channel:this.form.channel == undefined ? '' : this.form.channel.toString(),
          registerSource:this.form.registerSource == undefined ? 0 : this.form.registerSource,
          courseName:this.form.courseName == undefined ? '' : this.form.courseName,
          vipStatus:this.form.vipStatus == undefined ? 0 : this.form.vipStatus,
          registerDate:this.form.registerDate,
          registerEndDate:this.form.registerEndDate,
          lastPayStart:this.form.lastPayStart,
          lastPayEnd:this.form.lastPayEnd,
          vipEnd:this.form.vipEnd == undefined ? 0 : this.form.vipEnd,
          gmv:this.form.gmval,
          pageNo: this.pageNo,
          pageSize: 0,
        }).then((res) => {
          if (res.code === 200) {
            this.exportList = res.data.list
            if(this.exportList.length>0){
              this.visibleShow = true
            }else{
              this.$message.warning('暂无导出数据')
            }
          }
        });
      },

        // 获取最近7天打开app次数
        appNum(data){
            OpenCount({
                userNo:data,
            }).then((res) => {
                if (res.code === 200) {
                    this.varsum = 0
                    let date = []
                    let num = []
                    let data = res.data
                    for(let i = 0; i<data.length; i++){
                        date.push(data[i].day)
                        num.push(data[i].count)
                        this.varsum += data[i].count
                    }

                    this.$nextTick(() => {
                        this.drawLine(date,num)
                    });
                }
            });
        },

        // 点击跳转用户详情
        userDetails(record){
            this.$router.push( `/userManage/UserList/UserDetails/${record.userNo}`);
        },

        // 查看用户归属变更
        userAttributionClick(record) {
            this.$router.push(`/userManage/ownershipList/OwnershipDetails/${record.id}`);
        },

        //锁定弹框
        isLockingClick(record){
            this.locking.name = record.nickName
            this.locking.phone = record.userName
            this.locking.userNo = record.userNo
            this.locking.isLocking = record.enableFlag
            this.visible = true
        },

        // 提交锁定状态
        handleOk(){
            LockUser({
                userNo: this.locking.userNo,
                enableFlag: this.locking.isLocking,
                desc: this.locking.remarks,
            }).then((res) => {
                if (res.code === 200) {
                    this.locking.remarks = ''
                    this.visible = false
                    this.getTagList()
                    this.$message.success('操作成功！')
                }
            });
        },

        //
        handleSearch(val){
            if(!val){
                this.options = []
            }else{
                SearchCourse({
                    word: val,
                }).then((res) => {
                    if (res.code === 200) {
                        this.options = []
                        let options = res.data
                        for(let i = 0; i < options.length; i++){
                            let obj = {}
                            obj.label = options[i].name
                            obj.value = options[i].name
                            this.options.push(obj)
                        }
                    }
                });
            }
        },

        // 点击取消按钮
        handleCancel(){
            this.locking.remarks = ''
        },

        drawLine(date,num) {
            let str = this.expandedRowKeys.toString()
            if(!str){
                return false
            }
            const chartDom = this.$refs.echartPicRef
            const myChart = echarts.init(chartDom);
            const option = {
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: date
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: num,
                        type: 'line'
                    }
                ]
            };

            option && myChart.setOption(option);
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
.contentRender{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    li {
        text-align: center;
        list-style: none;
    }
    .tables{
        width: 50%;
        .items{
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;
            background: rgba(80, 207, 248, 0.2);
            >.title{
                width: 30%;
                font-size: 13px;
                text-align: right;
                font-weight: 500;
            }
            .substance{
                width: 70%;
                font-size: 13px;
                text-align: left;
                font-weight: 500;
            }
        }
    }
    .chart{
        width: 50%;
        .chartCom{
            width: 100%;
            height: 320px;
            margin-top: -10px;
        }
        .chartTitle{
            font-size: 15px;
            font-weight: 500;
        }
    }
}

.item{
    margin-right: 20px;
    margin-bottom: 10px;
}
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    margin-bottom: 30px;
}

.avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
    flex-wrap: wrap;
}
/deep/.vue-treeselect__control{
    width: 210px;
}
/deep/ .ant-input{
    min-height: 36px !important;
}
/deep/ .ant-select-selection--single{
    min-height: 36px !important;
}
/deep/.ant-select-selection__rendered{
    line-height: 35px;
}
</style>
